import React, { Component, Fragment } from 'react'
import Banner from '../Banner/Banner'
import Header from '../Header/Header'

import banner_top from '../../banners/banner-top.png'
class Layout extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Banner image={banner_top} name="Sillas okamura" />
                {this.props.children}
            </Fragment>
        )
    }
}

export default Layout
