import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './PrimaryProduct.css'

import data from '../../data-new.json'

const products = data.data

class PrimaryProduct extends Component {

    render() {
        const { num, title_top, title_bottom } = this.props
        const num1 = parseInt(num) + 1;
        
        return (
            <div className="container-fluid product-display">
                <div className="product-image">
                    <img src={products[num].img}  alt="..."/>
                </div>
                <div className=" par">
                    <h1 className="title">{title_top}<br/>{title_bottom}</h1>
                    <hr />
                    <h2 className="subtitle">{products[num].Producto}</h2>
                    <p className="paragraph">{products[num].Descripcion}</p>
                    <Link to={`/product-view/${num1}`} className="btn btn-secondary btn-lg">Ver más..</Link>
                </div>
            </div>
        )
    }
}

export default PrimaryProduct
