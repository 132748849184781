import React, { Component, Fragment } from 'react'
import ViewProduct from '../components/ViewProduct/ViewProduct'
import RandomProducts from '../components/RandomProducts/RandomProducts'
import Footer from '../components/Footer/Footer'
class ProductView extends Component {
    render() {
        return (
            <Fragment>
                <ViewProduct />
                <RandomProducts />
                <Footer />
            </Fragment>
        )
    }
}

export default ProductView
