import React, { Component } from 'react'

import Carousel from 'react-bootstrap/Carousel'
class Banner extends Component {

    render() {
        const { image, name } = this.props

        return (
            <Carousel 
                nextIcon={''} 
                prevIcon={''}
                nextLabel={''}
                prevLabel={''}
            >
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={ image }
                    alt={ name }
                    />
                </Carousel.Item>
            </Carousel>    
        )
    }
}

export default Banner
