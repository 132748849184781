import React, { Component, Fragment } from 'react'

import PrimaryProduct from '../components/PrimaryProduct/PrimaryProduct' 
import Banner from '../components/Banner/Banner' 
import Products from '../components/Products/Products' 

import banner_bottom from '../banners/banner-bottom.png'
export class Home extends Component {
    render() {
        return (
            <Fragment>
                <PrimaryProduct 
                    num="7" 
                    title_top="COMODIDAD, DISEÑO" 
                    title_bottom="Y TECNOLOGÍA" 
                />
                <Products />
                <PrimaryProduct 
                    num="0" 
                    title_top="LA SILLA CON EL" 
                    title_bottom="AJUSTE PERFECTO" 
                />
                <Banner image={banner_bottom} name="" />
            </Fragment>
        )
    }
}

export default Home
