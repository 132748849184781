import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import data from '../../data-new.json'

import './RandomProducts.css'

const newData = data.data



const RandomProducts = () => {
        return (
            <Fragment>
                <div className="container-fluid RandomProducts">
                    <div className="Random-container">
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={`/product-view/${1}`}
                            className="title"
                        >
                            <div className="Random-card" >
                                <img className="card-img-top image" src={newData[0].img} alt={newData[0].Producto} />
                                <div className="card-body">
                                    <span>{newData[0].Producto}</span>
                                </div>
                            </div>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={`/product-view/${4}`}
                            className="title"
                        >
                            <div className="Random-card" >
                                <img className="card-img-top image" src={newData[3].img} alt={newData[3].Producto} />
                                <div className="card-body">
                                    <span>{newData[3].Producto}</span>
                                </div>
                            </div>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={`/product-view/${7}`}
                            className="title"
                        >
                            <div className="Random-card" >
                                <img className="card-img-top image" src={newData[6].img} alt={newData[6].Producto} />
                                <div className="card-body">
                                    <span>{newData[6].Producto}</span>
                                </div>
                            </div>
                        </Link>
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={`/product-view/${11}`}
                            className="title"
                        >
                            <div className="Random-card" >
                                <img className="card-img-top image" src={newData[10].img} alt={newData[10].Producto} />
                                <div className="card-body">
                                    <span>{newData[10].Producto}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </Fragment>
        )
    }


export default RandomProducts
