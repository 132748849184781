import React, { Component, Fragment } from 'react'

import './Footer.css'

import whatsapp from '../../images/whatsapp.svg'

class Footer extends Component {

    render() {
        return (
            <Fragment>
                <div className="container footer">
                    <div className="foot foot_left">
                        <a className="foot foot_left_a" href="https://ofiplan.mx/contacto/">
                            <span>Solicite una <strong>cotización</strong></span>
                            <br />
                            <span>o descargue nuestro catálogo</span>
                        </a>
                    </div>
                    <div className="foot_right">
                        <a className="foot foot_right_a" href="https://api.whatsapp.com/send?phone=5213323103036&text=Hola,%20necesito%20informes." target="_blank" rel="noreferrer">
                            <img className="foot_img_whatsapp" src={whatsapp} alt="WhatsApp Logo"/>
                            <span className="foot_whatsapp">CONTACTA POR WHATSAPP</span>                                    
                        </a>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Footer
