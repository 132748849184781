import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Header.css'


class Header extends Component {

    render() {
        return (
            <div className="container-fluid">
                <header>
                    <div className="row">
                        <div className="top left col-6"></div>
                        <div className="top right col-6">
                            <a
                                className="homeBtn inicio" 
                                href="https://ofiplan.mx/"
                                rel="noreferrer nofollow"
                            >
                               Ofiplan.mx
                            </a>
                        </div>   
                    </div>
                    <div className="row header--container_info">
                        <div className="row header-image">                        
                            <figure className="">
                                <img src="/img/ofiplanLogo.png" className="ofiplan" alt="Logo Ofiplan" />
                            </figure>
                        </div>
                        <div className="row header-info">
                            <div className="header--title-container">
                                <h1>
                                    Llame y con gusto lo asesoramos
                                </h1>
                                <p>
                                    <a href="tel:+523319316567">33 1931 6567</a>
                                </p>
                                <p className="header--whatsapp">
                                    <a href="https://api.whatsapp.com/send?phone=5213323103036&text=Hola,%20necesito%20informes%20de%20Ofiplan." target="_blank" rel="noreferrer">
                                        <img src="/img/whatsapp-ofiplan.svg" alt="Logo WhatsApp"/>
                                        <span>Contacto por WhatsApp</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="top right bottomBtn col-5">
                            <Link                                
                                style={{ textDecoration: 'none' }}
                                className="homeBtn" 
                                to="/">
                                Alto Diseño
                            </Link>
                        </div>  
                    </div>
                </header>
            </div>
        )
    }
}

export default Header