import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react'
import Layout from './components/Layout/Layout';
import Home from './pages/Home';
import ProductView from './pages/ProductView';

// Bootstrap 
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ScrollToProduct() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.screen.width > 600) {
      window.scrollTo(900, 900);
    } else {
      window.scrollTo(600, 600);
    }
  }, [pathname]);

  return null;
}

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Layout className="App">
          <Route exact path="/" children={
            <div>
              <ScrollToTop /> 
              <Home/>
            </div>
          } />
          <Route exact path="/product-view/:id" children={
            <div>
              <ScrollToProduct />
              <ProductView />
            </div>
          } />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
