import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Products.css'

import data from '../../data-new.json'

const newData = data.data

class Products extends Component {

    render() {
        return (
            <div className="container-fluid prod">
                <span className="productos">Productos</span>
                <div className="add">
                    {
                        newData.map(({ id, Producto, img }) => (
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={`/product-view/${id}`}
                                className="card-product"
                                key={id}
                            >
                                <div className="card" >
                                    <img className="card-img-top image" src={img} alt={Producto} />
                                    <div className="card-body">
                                        <span>{Producto}</span>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default Products
